//before after, suwak, ga360

let setBeforeAfterSize = function () {
  document.querySelectorAll('.before-after').forEach((slider) => {
    slider.querySelectorAll('img').forEach((img) => {
      img.style.width = slider.offsetWidth + 'px';
    });

    if(slider.querySelectorAll('.before-after__desc').length > 0) {
      slider.querySelectorAll('.before-after__desc').forEach((desc) => {
        desc.style.width = slider.offsetWidth + 'px';
      });

      slider.querySelector('.desc-after').style.height = 'auto';
      slider.querySelector('.desc-before').style.height = 'auto';

      if (
        slider.querySelector('.desc-after').clientHeight <
        slider.querySelector('.desc-before').clientHeight
      ) {
        slider.querySelector('.desc-after').style.height =
            slider.querySelector('.desc-before').clientHeight + 'px';
      } else {
        slider.querySelector('.desc-before').style.height =
            slider.querySelector('.desc-after').clientHeight + 'px';
      }
    }
  });
};

window.addEventListener('DOMContentLoaded', function () {
  setBeforeAfterSize();
});

document.querySelectorAll('.before-after').forEach((slider) => {
  // deviceTouch
  var artID = slider.closest('.__js_stats_wrapper').getAttribute('data-id');
  const ga360id = slider.closest('.before-after').getAttribute('data-ga360-id');
  const moduleName = slider.closest('.before-after').getAttribute('data-id');
  if (!ga360id) {
    console.log(
        '%c dodać data-ga360-id do suwaka jesli mają iść staty!',
        'background: #3b3b3b; padding: 3px; color: #beeb2f'
    );
  } else {
    statElementCollector[ga360id + '-' + artID] = {
      id: ga360id,
      moduleName: moduleName,
      viewedCount: 0,
      itemCount: 2,
    };
  }
  $(this).statVievedSlide = [];
  //pozycje
  slidePositionVieved[ga360id + '-' + artID] = {};

  const range = document.createElement('input');
  range.type = 'range';
  let leftSeen = false;
  let rightSeen = false;
  range.addEventListener('input', function () {
    slider.querySelector('.img-before').style.width = this.value + '%';

    if (!leftSeen && this.value < 20) {
      sendStats(
          'before-after',
          'view',
          artID + ' / ' + moduleName,
          slider.querySelector('.img-after').getAttribute('src')
      );

      //obejrzany prawy
      slidePositionVieved[ga360id + '-' + artID][1] = 1;
      statElementCollector[ga360id + '-' + artID]['viewedCount'] = Object.keys(
          slidePositionVieved[ga360id + '-' + artID]
      ).length;

      WP.push(function () {
        WP.pwp.customAction({
          ga: {
            event: 'content_interaction',
            eventCategory: ga360id,
            moduleName: moduleName,
            eventAction: deviceTouch ? 'swipe' : 'show',
          },
        });
      });

      leftSeen = true;
      rightSeen = false;
    }
    if (!rightSeen && this.value > 80) {
      sendStats(
          'before-after',
          'view',
          artID + ' / ' + moduleName,
          slider.querySelector('.img-before__wrapper img').getAttribute('src')
      );

      //obejrzany lewy
      slidePositionVieved[ga360id + '-' + artID][2] = 1;
      statElementCollector[ga360id + '-' + artID]['viewedCount'] = Object.keys(
          slidePositionVieved[ga360id + '-' + artID]
      ).length;

      WP.pwp.customAction({
        ga: {
          event: 'content_interaction',
          eventCategory: ga360id,
          moduleName: moduleName,
          eventAction: deviceTouch ? 'swipe' : 'show',
        },
      });

      leftSeen = false;
      rightSeen = true;
    }
  });
  slider.appendChild(range);
});

$(window).resize(function () {
  setBeforeAfterSize();
});
