'use strict';

var galleriesHover = $('.gallery__patchwork').each(function () {
    //sprawdzamy, czy jest ustawiony id dla statystyk
    var $artID = $(this).parents('.__js_stats_wrapper').data('id');
    var ga360id = $(this).data('ga360-id');
    if (ga360id == undefined) {
        console.log(
            '%c dodać ga360-id do Galerii hover: ' + $(this).data('id'),
            'background: #553311; padding: 3px; color: #ffaaaa'
        );
    } else {
        statElementCollector[ga360id + '-' + $artID] = {
            id: ga360id,
            moduleName: $(this).data('id'),
            viewedCount: 0,
            itemCount: $(this).find('.gallery--hover-item').length,
        };
    }
    $(this).statVievedSlide = [];
    //pozycje

    slidePositionVieved[ga360id + '-' + $artID] = {};
});

$('.gallery__patchwork').on('mouseenter', '.patchwork__img', function () {
    if (isScrolling) return;
    var $artID = $(this).parents('.__js_stats_wrapper').data('id'),
        $galID = $(this).parents('.gallery__patchwork').data('id'),
        $imgID = $(this).find('img').attr('src'),
        ga360id = $(this).parents('.gallery__patchwork').data('ga360-id'),
        $elId = $(this).index() + 1;
    if (!ga360id) {
        console.log(
            '%c dodać ga360-id do patchworka!' +
            $galID +
            'background: #3b3b3b; padding: 3px; color: #beeb2f'
        );
    }
    var activeItem = $(this).index();
    //console.log('art:' + $artID + 'gal:' + $galID + ' / img: ' + $imgID);
    slidePositionVieved[ga360id + '-' + $artID][activeItem] = 1;
    statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
        slidePositionVieved[ga360id + '-' + $artID]
    ).length;

    sendStats('gallery', 'hover-' + $elId, $artID + ' / ' + $galID, $imgID);

    if (!isScrolligFlag) {
        WP.pwp.customAction({
            ga: {
                event: 'content_interaction',
                eventCategory: ga360id,
                moduleName: $galID,
                eventAction: deviceTouch ? 'tap' : 'hover',
            },
        });
    }
});
