(function () {
  var sTS = -1,
    T = [],
    checkPosition = function () {
      var hh = document.querySelector('header.header').scrollHeight, //header height
        vh = document.documentElement.clientHeight; //viewport height

      document
        .querySelectorAll('.article--ajax-wrapper')
        .forEach(function (e, n) {
          var tt = parseInt(e.getBoundingClientRect().top),
            bt = parseInt(e.getBoundingClientRect().bottom),
            g = parseInt(vh - tt - hh), //góra artykułu w viewport
            d = parseInt(bt - hh), //dół artykułu w viewport
            r = parseInt((e.scrollHeight + hh) / 4), //ratio
            statId = e.getAttribute('data-id');

          //Jeżeli nie ma jeszcze tablicy flag to ją utwórz
          if (typeof T[n] == 'undefined') {
            T[n] = [];
            //inicjacja tablic flag dla kolejnych ćwiartek
            for (var k = 1; k <= 4; k++) {
              T[n][k] = {};
              T[n][k].begin = 0;
              T[n][k].end = 0;
              T[n][k].send = 0;
            }
          }

          //czy artykuł jest w vieportcie
          if (g > 0 && d > 0) {
            if (typeof T[n][0] == 'undefined') {
              T[n][0] = 1;
              //PageViews
              sendStats('view', 'scroll', statId);
            }
          }

          for (var k = 1; k <= 4; k++) {
            if (
              !T[n][k].begin &&
              tt < -(k - 1) * r + vh + hh &&
              tt > -(k - 1) * r
            )
              T[n][k].begin = 1;
            if (!T[n][k].end && tt < -k * r + vh + hh && tt > -k * r)
              T[n][k].end = 1;
          }

          for (var k = 1; k <= 4; k++) {
            if (!T[n][k].send && T[n][k].begin && T[n][k].end) {
              sendStats('progress', 'scroll', statId, k * 25);
              T[n][k].send = 1;
            }
          }

          if (typeof window.debug) {
            $('#statArticle-' + n + '-0').html(T[n][0]);
            for (var k = 1; k <= 4; k++) {
              $('#statArticle-' + n + '-' + k + 'b').html(T[n][k].begin);
              $('#statArticle-' + n + '-' + k + 'e').html(T[n][k].end);
              if (T[n][k].send)
                $(
                  '#statArticle-' +
                    n +
                    '-' +
                    k +
                    'b, #statArticle-' +
                    n +
                    '-' +
                    k +
                    'e'
                ).css({ backgroundColor: '#bbffc3' });
              $('#statArticle-' + n + '-g').html(tt);
              $('#statArticle-' + n + '-d').html(bt + ' ratio: ' + r);
            }
          }
        });
    };

  window.addEventListener('load', function () {
    if (sTS > 0) clearTimeout(sTS);
    sTS = setTimeout(checkPosition, 750);
  });

  window.addEventListener('scroll', function () {
    if (sTS > 0) clearTimeout(sTS);
    sTS = setTimeout(checkPosition, 50);
  });
})();
